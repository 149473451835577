import React, { useState } from 'react'
// Image
import useAPI from '../../../hooks/useAPI';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Snackbar } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { BURL } from '../../../services/base';
import SmallLoader from '../../widgets/SmallLoader';
import { RoomHorizontalLinearStepper } from '../components/tenantLinearStepper';
import { RemoveRedEye } from '@mui/icons-material';
import { string } from 'yup';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function UnitDetails() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);
    const [loading, setLoading] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [updateModal, setupdateModal] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [errorText, setErrorText] = React.useState(null);
    const [success, setSuccess] = useState(null);
    const [rooms, setRooms] = React.useState([]);
    const [unitName, setUnitName] = React.useState('');
    const [unitId, setUnitId] = React.useState('');
    // const [propertyId, setPropertyId] = React.useState('');
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);
    const [room, setRoom] = React.useState(string);
    const [roomTag, setRoomTag] = React.useState('');
    const [roomId, setRoomId] = React.useState(null);
    const [unitRoom, setUnitRoom] = React.useState({
        unitId: 0,
        propertyId: 0,
        roomId: 0,
        tag: ''
    });
    const { propertyId, id } = useParams();

    const { POST, deleteRequest } = useAPI();
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;

    const handleConfirmation = (roomId) => {
        setConfirmationOpen(true);
        setRoomId(roomId)
    };

    const handleClickOpen = (title, room) => {
        if (title == 'add_modal') {
            setShowModal(true);
        } else {
            console.log('roomDet', room);
            setUnitRoom({
                unitId: room.unitId,
                propertyId: room.propertyId,
                roomId: room.id,
                tag: room.tag
            });
            setRoom(room);
            setRoomTag(room.tag);
            setupdateModal(true);
        }
    };

    const handleClose = () => {
        setupdateModal(false);
        setRoom([]);
        setAnchorEl(null);
    };

    const handleSnackbarClose = (
        event,
        reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({ ...state, open: false });
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setRoomTag(value);
    };

    const getRoomByUnit = React.useCallback(async () => {
        const token = localStorage.getItem('token');
        setLoading(true);
        setErrorText(null);
        const response = await axios.get(`${BURL}/admin/properties/units/${id}/rooms`, {
            params: {
                withProperty: true,
                withUnit: true,
                withTenants: true
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        setLoading(false);

        if (response?.status) {
            const res = response.data.data;
            console.log('resjj', res);

            setRooms(res);
            setUnitName(res[0].unit.name);
            setUnitId(res[0].unit.id);
            // setPropertyId(res[0].unit.propertyId);
        } else {
            // setErrorText(response.message ?? 'Request Error');
        }
    }, []);

    React.useEffect(() => {
        getRoomByUnit();
    }, []);

    const updateRoom = (newState) => async () => {
        setConfirmLoading(true);
        setErrorText(null);
        unitRoom.tag = roomTag;
        try {
            const response = await POST(`admin/properties/units/rooms/update`, unitRoom);
            setLoading(false);
            if (response) {
                setState({ ...newState, open: true });
                getRoomByUnit();
                setRoom([]);
                handleClose();
            } else {
                setErrorText(response.message);
            }
        } catch (err) {
            console.log('err', err);
        }
        finally {
            setConfirmLoading(false);
        }
    }

    const deleteRoom = (newState) => async () => {
        const token = localStorage.getItem('token');
        setConfirmLoading(true);
        setErrorText(null);

        try {
            const url = `${BURL}/admin/room`;
            const body = { propertyId, roomId };

            const response = await deleteRequest(url, body, token);
            setSuccess(response.message);
            if (response?.status) {
                setState({ ...newState, open: true });
                setSuccess(response.message);
                getRoomByUnit();
            } else {
                setErrorText(response.message);
            }
        } catch (err) {
            setErrorText(err);
        } finally {
            setConfirmLoading(false);
            setConfirmationOpen(false);
        }
    };

    const loader = <SmallLoader />
    return (
        <>
            <div className="px-5 md:px-10 sm:px-3 flex flex-col gap-10 mt-5 md:mt-4 pt-3 mb-0">
                <div className='w-full text-[#6C6C6C] flex justify-between'>
                    <div className='flex gap-3'>
                        <Link to={'/dashboard/properties'} className='font-[600] text-[18px] md:text-[22px] cursor-pointer'>Properties</Link>
                        <Link to={`/dashboard/properties/${propertyId}`} className='mt-3 text-[12px] md:text-[14px] cursor-pointer'> > Property Details</Link>
                        <span className='mt-3 text-[12px] md:text-[14px]'> > {unitName}</span>
                    </div>
                </div>
            </div>
            <div className="md:px-10 sm:px-3 mt-5 md:mt-4 flex justify-end items-center pt-3 bg-white mb-0">
                <button className='px-4 py-2 hover:text-[#0FA958] hover:font-semibold flex items-center gap-1' onClick={() => handleClickOpen('add_modal', '')}> <AddIcon className='text-[#6C6C6C]' /> <p className='flex font-light text-[12px] md:text-[14px] items-center py-auto mt-2'>Add Room</p>
                </button>
                <Dialog
                    open={showModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setShowModal(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <RoomHorizontalLinearStepper open={showModal} setOpen={setShowModal} currentPropertyId={propertyId} currentUnitId={id} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
            <div className='w-full bg-white'>
                {/* Property section */}
                <div className="px-[1.22rem] md:px-12 bg-white w-full mb-10">
                    {/* <div className='pb-10 w-full'> */}
                    <p className='mb-3 font-semibold text-sm text-gray-400'>Rooms</p>
                    {loading && <SmallLoader />}
                    {(errorText && !loading) && errorText}
                    <div className='mt-24 flex gap-5 lg:mx-0 justify-center text-center'>{(!errorText && !loading && !rooms.length)
                        && 'There is currently no room attach to this unit. Click ADD ROOM to add a room to a unit.'}
                    </div>
                    {errorText && <Button onClick={getRoomByUnit}>Try again</Button>}
                    {!loading && rooms.length != 0 &&
                        <div className='flex flex-col gap-2 w-full text-[#6C6C6C] text-[10px] md:text-[12px] font-[300] overflow-x-auto'>
                            {/* List Title */}
                            <div className='flex w-full justify-between font-bold px-4'>
                                <span className='w-[80px] md:w-[150px]'>Room No</span>
                                <span className='w-[100px] md:w-[150px]'>Occupant</span>
                                <span className='w-[100px] md:w-[160px] px-3'>No in a Room</span>
                                <span className='w-[100px] md:w-[150px]'> Rent Status</span>
                                <span className='w-[100px] md:w-[150px]'> Action</span>
                            </div>
                            {/* Data */}
                            {rooms.map((room, index) =>
                                <Link key={index} className='bg-[#F1F1F1] flex justify-between px-4 py-3 text-[12px] md:text-[14px]'>
                                    <span className='w-[80px] md:w-[150px] text-[#0FA958] font-[500] capitalize'>{room.tag}</span>
                                    <span className='w-[100px] md:w-[150px] text-[#0FA958] font-[500]'></span>
                                    <span className='w-[100px] md:w-[150px] px-3'></span>
                                    <span className='w-[100px] md:w-[150px] text-[#BD2424] font-bold'></span>
                                    <div className='w-[100px] md:w-[150px] flex gap-4 items-center'>
                                        <Link to={`/dashboard/unit/${room.unitId}/room/${room.id}/tenants`}>
                                            < RemoveRedEye className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '18px' }} />
                                        </Link>
                                        < EditIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '18px' }} onClick={() => handleClickOpen('update_modal', room)} />
                                        {setupdateModal && <Dialog
                                            open={updateModal}
                                            TransitionComponent={Transition}
                                            keepMounted
                                            onClose={() => setupdateModal(false)}
                                            aria-describedby="alert-dialog-slide-description"
                                        >
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-slide-description">
                                                    <div sx={{ mt: 2, mb: 1 }}>
                                                        <div className="w-[20rem] lg:w-[30rem]">
                                                            <div className="my-10">
                                                                <h1 className='text-3xl font-bold text-black'> Room Details</h1>
                                                                <p className="text-[0.8em] mt-2">Enter the room information.</p>
                                                                {errorText && <div className='text-sm text-red-500 py-2'>{errorText}</div>}
                                                            </div>
                                                            <div className='flex flex-col gap-5'>
                                                                <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                                                    <label htmlFor="" className=''>Property</label>
                                                                    <input type="text" disabled className='disabled:text-[#000000DE] border-[0.75px] border-gray-300 focus:border-[#0FA958] px-2.5 py-3 outline-none focus:outline-none focus:border-2 rounded-[5px] text-[1rem] font-[400] placeholder:text-[#0000008f]' name='property' defaultValue={room?.property?.name} placeholder="Room BA" />
                                                                </div>

                                                                <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                                                    <label htmlFor="" className=''>Unit</label>
                                                                    <input type="text" disabled className='disabled:text-[#000000DE] border-[0.75px] border-gray-300 focus:border-[#0FA958] px-2.5 py-3 outline-none focus:outline-none focus:border-2 rounded-[5px] text-[1rem] font-[400] placeholder:text-[#0000008f]' name='unit' defaultValue={room?.unit?.name} placeholder="Room Unit" />
                                                                </div>

                                                                <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                                                    <label htmlFor="" className=''>Room Tag</label>
                                                                    <input type="text" className='capitalize text-[#000000DE] border-[0.75px] border-gray-300 focus:border-[#0FA958] px-2.5 py-3 outline-none focus:outline-none focus:border-2 rounded-[5px] text-[1rem] font-[400] placeholder:text-[#0000008f]' name='tag' value={roomTag} onChange={handleChange} placeholder="Room BA" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-end mt-5">
                                                            <Button sx={{ color: 'red' }} className='flex gap-1 items-center' onClick={handleClose}>
                                                                Close
                                                            </Button>
                                                            <Button className='flex gap-1 items-center' onClick={updateRoom({ vertical: 'bottom', horizontal: 'right' })}>
                                                                {!confirmLoading && <span>Update</span>}

                                                                {confirmLoading && loader}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </DialogContentText>
                                            </DialogContent>
                                        </Dialog>}
                                        < DeleteIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '18px' }} onClick={() => handleConfirmation(room.id)} />
                                    </div>
                                </Link>
                            )}

                            {/* <div className='bg-[#F1F1F1] flex justify-between px-4 py-3 text-[12px] md:text-[14px]'>
                     <span className='w-[80px] md:w-[150px] text-[#0FA958] font-[500]'>2</span>
                     <span className='w-[100px] md:w-[150px] text-[#0FA958] font-[500]'>Tems Aya Blessing</span>
                     <span className='w-[100px] md:w-[150px] px-3'>1</span>
                     <span className='w-[100px] md:w-[150px] text-[#0C9A00] font-bold'>Active</span>
                 </div>

                 <div className='bg-[#F1F1F1] flex justify-between px-4 py-3 text-[12px] md:text-[14px]'>
                     <span className='w-[80px] md:w-[150px] text-[#0FA958] font-[500]'>3</span>
                     <span className='w-[100px] md:w-[150px] text-[#0FA958] font-[500]'></span>
                     <span className='w-[100px] md:w-[150px] px-3'>0</span>
                     <span className='w-[100px] md:w-[150px] text-[#6C6C6C] font-bold'>Inactive</span>
                 </div> */}
                        </div>
                    }
                </div>
            </div>
            <Dialog
                open={confirmationOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete room?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this room?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmationOpen(false)}>No</Button>
                    <Button sx={{ color: 'red' }} onClick={deleteRoom({ vertical: 'bottom', horizontal: 'right' })} autoFocus className='text-[#BD2424]'>
                        {!confirmLoading && <span>Yes</span>}

                        {confirmLoading && loader}
                    </Button>
                </DialogActions>
            </Dialog>

            {success && <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {success}
                </Alert>
            </Snackbar>}
            {errorText && <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {errorText}
                </Alert>
            </Snackbar>}
        </>
    )
}

export default UnitDetails