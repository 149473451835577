import React, { useState } from 'react'
import TransitionalModal from "../components/transitional";
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import { BURL } from '../../../services/base';
// Image
import SmallLoader from '../../widgets/SmallLoader';
import useAPI from '../../../hooks/useAPI';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Snackbar } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useParams } from 'react-router-dom';
import HorizontalLinearStepper from '../components/linearStepper';
import TenantHorizontalLinearStepper from '../components/tenantLinearStepper';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function Tenancy() {
    const [loading, setLoading] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [errorText, setErrorText] = React.useState(null);
    const [success, setSuccess] = useState(null);
    const [properties, setProperties] = React.useState([]);
    const [tenants, setTenants] = React.useState([]);
    const [tenantsUnit, setTenantsUnit] = React.useState([]);
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);
    const [tenantRoomId, setTenantRoomId] = React.useState(false);

    const { unitId, id } = useParams();

    const { deleteRequest } = useAPI();
    const [state, setState] = React.useState({
        opened: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opened } = state;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Formats as yyyy-mm-dd
    };

    const getTenantsByUnitRoom = React.useCallback(async () => {
        const token = localStorage.getItem('token');
        console.log('UnitId', unitId);
        setLoading(true);
        setErrorText(null);
        const response = await axios.get(`${BURL}/admin/properties/units/${unitId}/rooms`, {
            params: {
                withProperty: false,
                withUnit: true,
                withTenants: true
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        setLoading(false);

        if (response?.status) {
            const res = await response.data.data
            console.log('tenants', res, id);
            const result = res.find(item => item.id == id);
            setTenantsUnit(result.unit);
            console.log('results', result);
            setTenants(result.tenants)
            // setRooms(res);
            // setUnitName(res[0].unit.name);
        } else {
            // setErrorText(response.message ?? 'Request Error');
        }
    }, [])

    React.useEffect(() => {
        getTenantsByUnitRoom();
    }, [getTenantsByUnitRoom])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleConfirmation = (roomId) => {
        setConfirmationOpen(true);
        setTenantRoomId(roomId)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSnackbarClose = (
        event,
        reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({ ...state, open: false });
    };


    const handleClickOpen = () => {
        setShowModal(true);
    };

    const removeTenant = (newState) => async (propertyId) => {
        const token = localStorage.getItem('token');
        setConfirmLoading(true);
        setErrorText(null);

        try {
            const url = `${BURL}/admin/tenant-room`;
            const body = { propertyId, tenantRoomId };
            const response = await deleteRequest(url, body, token);
            setSuccess(response.message);
            if (response?.status) {
                setSuccess(response.message)
                getTenantsByUnitRoom();
            } else {
                setErrorText(response.message);
            }
        } catch (err) {
            setErrorText(err);
        } finally {
            setLoading(false);
            setConfirmationOpen(false);
        }
    };

    const loader = <SmallLoader />
    return (
        <>
            <div className="px-5 md:px-10 sm:px-3 flex flex-col gap-10 mt-5 md:mt-4 pt-3 mb-0">
                <div className='w-full text-[#6C6C6C] flex justify-between'>
                    <div className='flex gap-3'>
                        <Link to={'/dashboard/properties'} className='font-[600] text-[18px] md:text-[22px] cursor-pointer'>Properties</Link>
                        <Link to={`/dashboard/properties/${tenantsUnit.propertyId}`} className='mt-3 text-[12px] md:text-[14px] cursor-pointer'> > Property Details</Link>
                        <Link to={`/dashboard/unit/${tenantsUnit.id}`} className='mt-3 text-[12px] md:text-[14px] cursor-pointer'> > {tenantsUnit.name}</Link>
                        <span className='mt-3 text-[12px] md:text-[14px]'> > Room</span>
                    </div>
                </div>
            </div>
            <div className="md:px-10 sm:px-3 mt-5 md:mt-4 flex justify-end items-center pt-3 bg-white mb-0">
                <button className='px-4 py-2 hover:text-[#0FA958] hover:font-semibold flex items-center gap-1' onClick={handleClickOpen}> <AddHomeWorkIcon className='text-[#6C6C6C]' /> <p className='flex font-light text-[12px] md:text-[14px] items-center py-auto mt-2'>Create Tenant</p>
                </button>
                <Dialog
                    open={showModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setShowModal(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <TenantHorizontalLinearStepper open={showModal} setOpen={setShowModal} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
            <div className='w-full bg-white'>
                {/* Property section */}
                <div className="px-[1.22rem] md:px-12 bg-white w-full mb-10">
                    {/* <div className='pb-10 w-full'> */}
                    <p className='mb-3 font-semibold text-sm text-gray-400'>Tenants</p>
                    {loading && <SmallLoader />}
                    {(errorText && !loading) && errorText}
                    <div className='mt-24 flex gap-5 lg:mx-0 justify-center text-center'>{(!errorText && !loading && !tenants.length)
                        && 'There is currently no tenant. Click CREATE TENANT to add a tenant to a property.'}
                    </div>
                    {errorText && <Button onClick={getTenantsByUnitRoom}>Try again</Button>}
                    {!loading && tenants.length != 0 &&
                        <div className='flex flex-col gap-2 w-full text-[#6C6C6C] text-[10px] md:text-[12px] font-[300] overflow-x-auto'>
                            {/* List Title */}
                            <div className='flex w-full justify-between font-bold px-4'>
                                <span className='w-[80px] md:w-[150px]'>Room No</span>
                                <span className='w-[100px] md:w-[150px]'>Name</span>
                                <span className='w-[100px] md:w-[150px]'>Email</span>
                                <span className='w-[100px] md:w-[150px] px-3'>Check In</span>
                                <span className='w-[100px] md:w-[150px]'> Check Out</span>
                                <span className='w-[100px] md:w-[150px]'> Action</span>
                            </div>
                            {/* Data */}
                            {tenants.map((tenant, index) =>
                                <div key={index} className='bg-[#F1F1F1] flex justify-between px-4 py-3 text-[12px] md:text-[14px]'>
                                    <span className='w-[80px] md:w-[150px] font-[500] capitalize mt-2'>{tenant.TenantRooms.tenantRoomId}</span>
                                    <span className='w-[100px] md:w-[150px] font-[500] capitalize mt-2'>{tenant.user.firstname} {tenant.user.lastname}</span>
                                    <span className='w-[100px] md:w-[150px] font-[500] mt-2'>{tenant.user.email}</span>
                                    <span className='w-[100px] md:w-[150px] px-3 mt-2'>{formatDate(tenant.TenantRooms.checkIn)}</span>
                                    <span className='w-[100px] md:w-[150px] mt-2'>{formatDate(tenant.TenantRooms.checkOut)}</span>
                                    {/* <span className='w-[100px] md:w-[150px] mt-2 text-[#BD2424] cursor-pointer font-[500]' onClick={() => handleConfirmation(tenant.TenantRooms.tenantRoomId)}>Remove Tenant</span> */}
                                    <div className='w-[100px] md:w-[150px] flex gap-4 items-center'>
                                        {/* < EditIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '14' }} /> */}
                                        < DeleteIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '18px' }} onClick={() => handleConfirmation(tenant.TenantRooms.tenantRoomId)} />
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
            <Dialog
                open={confirmationOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Remove tenant from room?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove this tenant?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmationOpen(false)}>No</Button>
                    <Button sx={{color: 'red'}} onClick={() => removeTenant({ vertical: 'bottom', horizontal: 'right' })(tenantsUnit.propertyId)} autoFocus className='text-[#BD2424]'>
                    {!confirmLoading && <span>Yes</span>}

{confirmLoading && loader}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <div className="md:px-10 sm:px-3 flex justify-between items-center py-3 border-b-2 bg-white">
                <h1 className="font-bold">  Properties</h1>
            </div> */}
                 {success && <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {success}
                </Alert>
            </Snackbar>}
            {errorText && <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {errorText}
                </Alert>
            </Snackbar>}
        </>
    )
}

export default Tenancy